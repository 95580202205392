import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    clinica: {
    },
    especialidades: [],
    servicios: [],
    loadingServicios: false,
    doctors: [],
    loadingDoctors: false,
    especies: [],
    razas: [],
    clientes: [],
    loadingClientes:false,
    loadingProgramacion: false,
    programacion:[],
    successCreateNuevaAgenda: false,
    agendavetConsulta:[],
    loadingAgendavetConsulta: false,
    agendavetConfirma:[],
    loadingAgendavetConfirma: false,
    loadingConfirmacionAgenda: false,
    loadingAnulacionAgenda: false,
    successCreateAnulacionAgenda: false,
    successCreateConfirmacionAgenda:false,
  },
  mutations: {
    addClinica(state, payload) {
      state.clinica = payload
    },
    addEspecialidades(state, payload) {
      state.especialidades = payload
    },
    addServicios(state, payload) {
      state.servicios = payload
    },
    setLoadingServicios(state, payload) {
      state.loadingServicios = payload
    },
    addDoctors(state, payload) {
      state.doctors = payload
    },
    addEspecies(state, payload) {
      state.especies = payload
    },
    addRazas(state, payload) {
      state.razas = payload
    },
    addClientes(state, payload){
      state.clientes = payload
    },
    setLoadingDoctors(state, payload) {
      state.loadingDoctors = payload
    },
    setLoadingClientes(state, payload) {
      state.loadingClientes = payload
    },
    setLoadingProgramacion(state, payload) {
      state.loadingProgramacion = payload
    },
  
    addProgramacion(state, payload){
      state.programacion = payload
    },
    setLoadingAgendavetConsulta(state, payload) {
      state.loadingAgendavetConsulta = payload
    },
    addAgendavetConsulta(state, payload){
      state.agendavetConsulta = payload
    },
    setLoadingAgendavetConfirma(state, payload) {
      state.loadingAgendavetConfirma = payload
    },
    addAgendavetConfirma(state, payload){
      state.agendavetConfirma = payload
    },
    setSuccessCreateNuevaProgrmacion(state, payload){
      state.successCreateNuevaAgenda = payload
    },
    setLoadingConfirmacionAgenda(state, payload){
      state.loadingConfirmacionAgenda = payload
    },
    setSuccessCreateConfirmacionAgenda(state, payload){
      state.successCreateConfirmacionAgenda = payload
    },
    setLoadingAnulacionAgenda(state, payload){
      state.loadingAnulacionAgenda = payload
    },
    setSuccessCreateAnulacionAgenda(state, payload){
      state.successCreateAnulacionAgenda = payload
    },
  },
  actions: {
    async getClinica({ commit, dispatch }, data) {
      let query = `https://service.clivenapp.cl/clinica?token=${data.token}`
      let headers = {
        "Content-type": "application/json",
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('addClinica', result.data)
            dispatch("getEspecialiades", { token: data.token })
            dispatch("getEspecies", { token: data.token })
            dispatch("getRazas", { token: data.token })
          }
        })
    },
    async getEspecialiades({ commit }, data) {
      let query = `https://service.clivenapp.cl/especialidad?token=${data.token}`
      let headers = {
        "Content-type": "application/json",
      }
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('addEspecialidades', result.data)
          }
        })
    },
    async getServiciosByEspecialidad({ commit }, data) {
      let query = `https://service.clivenapp.cl/produ/especialidad?token=${data.token}&especialidad=${data.especialidad}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingServicios', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('addServicios', result.data)
            commit('setLoadingServicios', false)
          }
        })
    },
    async getDoctorsByEspecialidad({ commit }, data) {
      let query = `https://service.clivenapp.cl/doctors/especialidad?token=${data.token}&especialidad=${data.especialidad}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingServicios', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('addDoctors', result.data)
            commit('setLoadingDoctors', false)
          }
        })
    },
    async getEspecies({ commit }, data) {

      let query = `https://service.clivenapp.cl/especie?token=${data.token}`
      await axios.get(query)
        .then((result) => {
          commit('addEspecies', result.data)
        })
        .catch((error) => {
          // console.log(error)
        })
    },
    async getRazas({ commit }, data) {

      let query = `https://service.clivenapp.cl/razas?token=${data.token}`
      await axios.get(query)
        .then((result) => {
          commit('addRazas', result.data)
        })
        .catch((error) => {
          // console.log(error)
        })
    },
    async getAgendavetByEmail({ commit }, data) {
      commit('setLoadingAgendavetConsulta', true)
      let query = `https://service.clivenapp.cl/agenda/vet?fecha=${data.fecha}&email=${data.email}`
      await axios.get(query)
        .then((result) => {
          commit('addAgendavetConsulta', result.data)
          commit('setLoadingAgendavetConsulta', false)
        })
        .catch((error) => {
          // console.log(error)
        })
    },
    async getAgendavetByStatusEmail({ commit }, data) {
      commit('setLoadingAgendavetConfirma', true)
      console.log("consultando")
      let query = `https://service.clivenapp.cl/agenda/vet?fecha=${data.fecha}&email=${data.email}&estado=${data.estado}`
      await axios.get(query)
        .then((result) => {
          commit('addAgendavetConfirma', result.data)
          commit('setLoadingAgendavetConfirma', false)
        })
        .catch((error) => {
          // console.log(error)
        })
    },
    async getClientesByEmail({ commit }, data) {
      let query = `https://service.clivenapp.cl/clientes/email?token=${data.token}&email=${data.email}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingClientes', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            console.log(result.data)
            commit('addClientes', result.data)
            commit('setLoadingClientes', false)
          }
        })
    },
    async getProgrmacionByRutDateAgenda({ commit }, data) {
      let query = `https://service.clivenapp.cl/programacion/agenda?token=${data.token}&rut=${data.rut}&start_date=${data.start_date}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingProgramacion', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('addProgramacion', result.data)
            commit('setLoadingProgramacion', false)
          }
        })
    },
    async postProgramacionAgenda({ commit }, data) {
      let query = `https://service.clivenapp.cl/agenda?token=${data.token}`
      let headers = {
        "Content-type": "application/json",
      }
      console.log(data.programacionAgenda)
      await axios.post(query,data.programacionAgenda, { headers: headers })
        .then((result) => {
          if (result.status == "201") {
            console.log("respondio un 201")
            commit('setSuccessCreateNuevaProgrmacion', true)
          }
        })
    },
    async getConfirmacionAgendaById({ commit, dispatch }, data) {
      let query = `https://service.clivenapp.cl/agenda/confirma/${data.id}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingConfirmacionAgenda', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('setLoadingConfirmacionAgenda', false)
            commit('setSuccessCreateConfirmacionAgenda', true)
            dispatch('getAgendavetByEmail', {email: data.email, fecha: new Date().toISOString().substring(0,10)})
          }
        })
    },
    async getConfirmacionAgendaByIdView({ commit }, data) {
      let query = `https://service.clivenapp.cl/agenda/confirma/${data.id}`
      let headers = {
        "Content-type": "application/json",
      }
      let agenda = {}
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            agenda = result.data
          }
        })
        return agenda
    },
    async getAnulacionAgendaByIdView({ commit }, data) {
      let query = `https://service.clivenapp.cl/agenda/anula/${data.id}`
      let headers = {
        "Content-type": "application/json",
      }
      let agenda = {}
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            agenda = result.data
          }
        })
        return agenda
    },
    async getConfirmacionAgendaById({ commit, dispatch }, data) {
      let query = `https://service.clivenapp.cl/agenda/confirma/${data.id}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingConfirmacionAgenda', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('setLoadingConfirmacionAgenda', false)
            commit('setSuccessCreateConfirmacionAgenda', true)
            dispatch('getAgendavetByEmail', {email: data.email, fecha: new Date().toISOString().substring(0,10)})
          }
        })
    },
    async getAnulacionAgendaById({ commit, dispatch }, data) {
      let query = `https://service.clivenapp.cl/agenda/anula/email/${data.id}`
      let headers = {
        "Content-type": "application/json",
      }
      commit('setLoadingAnulacionAgenda', true)
      await axios.get(query, { headers: headers })
        .then((result) => {
          if (result.status == "200") {
            commit('setLoadingAnulacionAgenda', false)
            commit('setSuccessCreateAnulacionAgenda', true)
            
          }
        })
    },

    
  },
  modules: {
  }
})
