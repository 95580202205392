import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: false,
      },
  // Colores de la aplicación
    themes: {
      light: {
        primary: '#0D47A1',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        background: "#FBFDFF",
        warning: '#FFC107'
      },
      
    },
  },
});